const keys = [
  'LANGUAGES',
  'PROJECT_TYPES',
  'SUB_PROJECT_TYPES',
  'INDUSTRIES',
  'TIME_SLOTS',
  'APPLICATION_STATUSES',
  'EXPERTISE_LEVELS',
  'QUALIFICATIONS',
  'COURSES',
  'PRICING_TYPES',
  'CRMS',
  'ASSESSMENT_TYPES',
  'TYPINGASSESSMENTTYPE_TYPES',
  'JOBLOCATION_TYPES',
  'WORKPREFERENCE_TYPES',
  'FEATURE_FLAGS',
  'SKILLS',
  'ADDRESS_OF_GEOLOCATION',
  'BANK_WITH_IFSC',
  'WORK_SPACE_SETUP',
  'LEAVE_CATEGORIES',
  'COMPANY_TYPES',
  'REJECT_REASONS',
  'RANKINGS',
  'SERVICES',
  'SOFTWARE_TOOLS',
  'TELEPHONY_PROVIDERS',
  'COMPLIANCE_CERTIFICATIONS',
  'OFFICE_TYPES',
  'MARKET_TYPES',
];

const suffixes = ['', '_START', '_FINISHED', '_ERROR'];

const types = {};

keys.forEach((k) => {
  suffixes.forEach((s) => {
    const value = 'FETCH_' + k + s;
    types[value] = value;
  });
});

types.RESET_DETAILS_OF_IFSC = 'RESET_DETAILS_OF_IFSC';

export default types;
